/* global Logger, google */

const GOOGLE_MAPS_VERSION = '3.57'

window.addEventListener('DOMContentLoaded', function () {
  document.querySelectorAll('.requires-geoloc').forEach(formRequiresGeoloc => {
    // Some forms don't have the geoloc_autocomplete input so we need to do this before the return bellow
    formRequiresGeoloc.onsubmit = undefined // These forms are supposed to have onsubmit="return false" to prevent submits before this script is loaded
  })
  const geolocInput = document.getElementById('geoloc_autocomplete')
  if (!geolocInput) {
    return
  }
  const mapsScript = document.createElement('script')
  document.head.appendChild(mapsScript)

  function attachLoadingFailedAlertOnInput () {
    geolocInput.addEventListener('focus', () => {
      window.alert('Veuillez recharger la page car une erreur s\'est produite lors du chargement. Si le problème persiste, veuillez essayer un autre navigateur/appareil.')
    }, {once: true})
  }

  mapsScript.onerror = () => {
    Logger.debug('Failed to load google places api')
    attachLoadingFailedAlertOnInput()
  }
  mapsScript.onload = () => {
    if (!window.google) {
      Logger.debug('google object undefined')
      attachLoadingFailedAlertOnInput()
      return
    }

    if (String.prototype.startsWith && !google.maps.version.startsWith(GOOGLE_MAPS_VERSION)) {
      Logger.warn(`Google Maps API version ${GOOGLE_MAPS_VERSION} is no longer accessible; the application is using the fallback version ${google.maps.version}`)
    }

    try {
      const circle = new google.maps.Circle({
        // Center of Paris
        center: {
          lat: 48.860165,
          lng: 2.346525,
        },
        radius: 50 * 1000, // in meters
      })
      const autocomplete = new google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */geolocInput,
        {
          types: ['geocode'],
          bounds: circle.getBounds(),
        })
      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace()
        // https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete
        if (place.geometry) {
          document.getElementById('geoloc_lat_lng').value = place.geometry.location.lat() + ',' + place.geometry.location.lng()
          document.getElementById('geoloc_locality').value = ''
          place.address_components.forEach(function (e) {
            // In the docs, it says that locality doesn't have zipcode, but it seems that few have ones.
            // But, every city have "locality" in the types array
            if (e.types.includes('postal_code')) {
              document.getElementById('geoloc_zip_code').value = e.short_name
            } else if (e.types.includes('locality') && place.types.includes('locality')) {
              document.getElementById('geoloc_locality').value = e.short_name
            }
          })
          geolocInput.value = place.formatted_address
          geolocInput.classList.remove('input-error')
        }
      })
      geolocInput.addEventListener('keydown', event => {
        // Block submit on keyboard 'enter' when the autocomplete dropdown is visible
        // This 'enter' key stroke is for choosing a suggestion from the dropdown, not for submitting the form
        const autosuggestElement = document.querySelector('.pac-container')
        if (event.keyCode === 13 && autosuggestElement && autosuggestElement.style.display !== 'none') {
          event.preventDefault()
        }
      })
      geolocInput.addEventListener('input', () => {
        // Invalidate geoloc to prevent a submit that would have an address that doesn't match lat lng
        document.getElementById('geoloc_lat_lng').value = ''
        document.getElementById('geoloc_zip_code').value = ''
        document.getElementById('geoloc_locality').value = ''
      })
    } catch (e) {
      Logger.debug('Failed to initialize autocomplete', e)
      attachLoadingFailedAlertOnInput()
    }
  }
  mapsScript.setAttribute('defer', '')
  mapsScript.setAttribute('async', 'false')
  const googleApiKey = document.getElementById('google-apis-key').innerHTML
  mapsScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?v=${GOOGLE_MAPS_VERSION}&libraries=places&key=${googleApiKey}`)

  geolocInput.form.addEventListener('submit', (event) => {
    if (geolocInput.disabled) {
      return
    }
    if (!document.getElementById('geoloc_lat_lng').value) {
      geolocInput.classList.add('input-error')
      // scroll if the element is not visible
      if (geolocInput.getBoundingClientRect().top < 0 || geolocInput.getBoundingClientRect().bottom > window.innerHeight) {
        geolocInput.scrollIntoView()
      }
      event.preventDefault()
      event.stopPropagation()
    }
  })
})
