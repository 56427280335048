document.addEventListener('DOMContentLoaded', () => {
    document.body.addEventListener("submit", event => {
        const form = event.target as HTMLFormElement;

        if (form instanceof HTMLFormElement && form.classList.contains("prevent-multiple-submit")) {
            if (form.hasAttribute("already-submitted")) {
                Logger.info('Prevent submit');
                event.preventDefault();
            } else {
                form.setAttribute("already-submitted", "true");
            }
        }
    })
})
